<template>
  <section class="bg-light h-full">
    <div class="container py-8">

      <div class="text-primary font-display font-bold text-5xl text-center mb-4">
        Folletos científicos
      </div>

      <div class="text-primary text-center mb-8">
        Elegir los temas de interés para obtener más información sobre la alimentación de los lactantes y niños.
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

        <div 
          class="thumbnail h-56 rounded-xl p-4 bg-cover bg-center flex flex-col justify-end cursor-pointer" 
          v-for="(item, idx) in list"
          :key="`thumbnail-${idx}`"
          :style="{ backgroundImage: `url('${item.imageUrl}')`}" 
          @click="thumbClick(idx)"
        >
          <div class="font-display text-2xl text-white title" v-html="item.title"></div>
        </div>

      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScientificBrochures",
  setup() {

    const list = [
      {
        title: `Fórmulas de<br/>Rutina Friso`,
        file: `triptico-frisolac-friso.pdf`,
        imageUrl: `/img/scientific-brochures/item-a.png`,
      },
      {
        title: `Sistema de Alergias<br/>Frisolac Gold`,
        file: `sistema-de-alergias-frisolac-gold-movil.pdf`,
        imageUrl: `/img/scientific-brochures/item-b.png`,
      },
      {
        title: `Importancia de la<br/>Lactosa en la Dieta Humana`,
        file: `importancia-de-la-lactosa-en-la-dieta-humana.pdf`,
        imageUrl: `/img/scientific-brochures/item-c.png`,
      },
      {
        title: `Guía para una Buena<br/>alimentación complementaria`,
        file: `manual-alimentacion-complementaria.pdf`,
        imageUrl: `/img/scientific-brochures/item-d.png`,
      },
    ];

    const thumbClick = (idx: number) => {
      const item = list[idx];
      const imageRepo = 'https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=doc&file=scientific-brochures/';
      const fileUrl = imageRepo + item['file'];
      window.open( fileUrl );
    }

    return {
      list,
      thumbClick,
    }
  }
});
</script>

<style scoped lang="scss">

.thumbnail {
  position: relative;

  &:before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0.7;
    z-index: 0;
    border-radius: 10px;

    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    // background-image: url("/img/icon-play--white.svg");
    background-size: 40px auto;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .title {
    text-shadow: 0 0 3px rgba(0,0,0,.5);
    z-index: 10;
  }
}
</style>