
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScientificBrochures",
  setup() {

    const list = [
      {
        title: `Fórmulas de<br/>Rutina Friso`,
        file: `triptico-frisolac-friso.pdf`,
        imageUrl: `/img/scientific-brochures/item-a.png`,
      },
      {
        title: `Sistema de Alergias<br/>Frisolac Gold`,
        file: `sistema-de-alergias-frisolac-gold-movil.pdf`,
        imageUrl: `/img/scientific-brochures/item-b.png`,
      },
      {
        title: `Importancia de la<br/>Lactosa en la Dieta Humana`,
        file: `importancia-de-la-lactosa-en-la-dieta-humana.pdf`,
        imageUrl: `/img/scientific-brochures/item-c.png`,
      },
      {
        title: `Guía para una Buena<br/>alimentación complementaria`,
        file: `manual-alimentacion-complementaria.pdf`,
        imageUrl: `/img/scientific-brochures/item-d.png`,
      },
    ];

    const thumbClick = (idx: number) => {
      const item = list[idx];
      const imageRepo = 'https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=doc&file=scientific-brochures/';
      const fileUrl = imageRepo + item['file'];
      window.open( fileUrl );
    }

    return {
      list,
      thumbClick,
    }
  }
});
